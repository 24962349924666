import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { useState } from "react"
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { FaWhatsapp } from "react-icons/fa"
import {
  FiActivity,
  FiAward,
  FiCode,
  FiGlobe,
  FiMail,
  FiTerminal,
} from "react-icons/fi"
import {
  DiCss3,
  DiHtml5,
  DiPhp,
  DiJsBadge,
  DiLinux,
  DiMysql,
  DiNodejs,
  DiReact,
  DiWindows,
  DiWordpress,
} from "react-icons/di"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bgimage from "../images/hero-background.jpg"
import logo from "../images/logo.png"
import icondev from "../images/material-icons/code-white-36dp.svg"
import iconnet from "../images/material-icons/router-white-36dp.svg"
import bgnet from "../images/network-cover-background.jpg"
import bgdev from "../images/webdev-cover-background.jpg"

const heroBgStyle = {
  color: "blue",
  backgroundImage: "url(" + bgimage + ")",
}

const IndexPage = () => {
  const [tooltip, setTooltip] = useState("Copiar email")

  const renderTooltip = props => (
    <Tooltip id="email-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )
  return (
    <Layout>
      <SEO title="Home" />
      <div
        className="jumbotron-fluid min-vh-100 index-jumbotron dark text-white text-center"
        style={heroBgStyle}
      >
        <Container>
          <Row>
            <Col>
              <h1>Somos ChalteNet</h1>
              <p>
                Soluciones en telecomunicaciones, desarrollo web e informática.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md>
              <AnchorLink to="/#networking">
                <div
                  className="d-flex home-option-cover"
                  style={{ backgroundImage: "url(" + bgnet + ")" }}
                >
                  <div>
                    <img
                      src={iconnet}
                      width="60px"
                      height="60px"
                      className=""
                      alt="Networking"
                    />
                    <h2>Networking</h2>
                  </div>
                </div>
              </AnchorLink>
            </Col>

            <Col md>
              <AnchorLink to="/#webdev">
                <div
                  className="d-flex home-option-cover"
                  style={{ backgroundImage: "url(" + bgdev + ")" }}
                >
                  <div>
                    <img
                      src={icondev}
                      width="60px"
                      height="60px"
                      alt="WebDev"
                    />
                    <h2>WebDev</h2>
                  </div>
                </div>
              </AnchorLink>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="home-section">
        <Container>
          <a id="quienes-somos">
            <h1>Quiénes somos</h1>
          </a>
          <p>
            Somos un blablablabala lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Integer vel risus consequat, malesuada enim
            faucibus, auctor ante. Vestibulum elementum feugiat enim ut rutrum.
            Aliquam vitae iaculis leo. Pellentesque nec turpis quis elit
            venenatis eleifend. Nullam euismod, ipsum vitae pulvinar laoreet,
            est lectus gravida neque, sed scelerisque odio massa at ligula.
            Integer non tortor nec dui pulvinar tempus eu in ante. Praesent
            commodo auctor tincidunt. Phasellus sed sem vehicula, venenatis quam
            ac, vulputate nisi. Interdum et malesuada fames ac ante ipsum primis
            in faucibus. Phasellus ut tellus ac eros efficitur ultricies. Mauris
            aliquet tristique maximus. Cras nec porta dui, id feugiat se1.
          </p>
        </Container>
      </section>
      <section className="home-section sec-grad1">
        <Container>
          <a id="networking">
            <h1>Servicios de Networking y Telecomunicaciones</h1>
          </a>
          <Row className="mt-4">
            <Col sm className="text-center ">
              <FiAward color="#047" size={32} />
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vel risus consequat, malesuada enim faucibus, auctor ante.{" "}
              </p>
            </Col>
            <Col sm>
              <FiAward color="#047" size={32} />
              <p>
                Vestibulum elementum feugiat enim ut rutrum. Aliquam vitae
                iaculis leo. Pellentesque nec turpis quis elit venenatis
                eleifend. Nullam eui
              </p>
            </Col>
            <Col sm>
              <FiAward color="#047" size={32} />
              <p>
                Aliquam vitae iaculis leo. Pellentesque nec turpis quis elit
                venenatis eleifend. Nullam eui
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="home-section sec-grad2">
        <Container>
          <a id="webdev">
            <h1>Desarrollo web y Aplicaciones</h1>
          </a>
          <Row className="mt-4">
            <Col sm className="text-center ">
              <FiTerminal color="#047" size={32} />
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vel risus consequat, malesuada enim faucibus, auctor ante.{" "}
              </p>
            </Col>
            <Col sm>
              <FiCode color="#047" size={32} />
              <p>
                Vestibulum elementum feugiat enim ut rutrum. Aliquam vitae
                iaculis leo. Pellentesque nec turpis quis elit venenatis
                eleifend. Nullam eui
              </p>
            </Col>
            <Col sm>
              <FiActivity color="#047" size={32} />
              <p>
                Aliquam vitae iaculis leo. Pellentesque nec turpis quis elit
                venenatis eleifend. Nullam eui
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4">
              <h4>Tecnologías que utilizamos</h4>
              <div className="d-flex flex-wrap px-4 mt-2 justify-content-center">
                <div className="d-flex flex-column align-items-center">
                  <DiCss3 color="#047" size={48} />
                  <p className="small dark-gray">CSS3</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiHtml5 color="#047" size={48} />
                  <p className="small dark-gray">HTML5</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiJsBadge color="#047" size={48} />
                  <p className="small dark-gray">JavaScript</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiLinux color="#047" size={48} />
                  <p className="small dark-gray">Linux</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiMysql color="#047" size={48} />
                  <p className="small dark-gray">MySQL</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiNodejs color="#047" size={48} />
                  <p className="small dark-gray">Node.JS</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiPhp color="#047" size={48} />
                  <p className="small dark-gray">PHP7</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiReact color="#047" size={48} />
                  <p className="small dark-gray">React</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiWindows color="#047" size={48} />
                  <p className="small dark-gray">Windows</p>
                </div>
                <div className="d-flex flex-column align-items-center px-2 px-sm-3">
                  <DiWordpress color="#047" size={48} />
                  <p className="small dark-gray">Wordpress</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="home-section">
        <Container>
          <h1 class="sr-only ">Contacto</h1>
          <Row className="mt-4 home-contacto">
            <Col
              sm
              className="home-contacto-img text-center text-sm-right pb-4 pb-sm-0"
            >
              <img
                src={logo}
                width="200"
                height="150"
                className="d-inline-block align-top"
                alt="ChalteNet"
              />
            </Col>
            <Col sm className="d-flex flex-row flex-wrap ">
              <OverlayTrigger
                placement="right"
                delay={{ show: 50, hide: 100 }}
                overlay={renderTooltip}
              >
                <a
                  className="d-block btn-link link w-100"
                  onClick={() => {
                    navigator.clipboard.writeText("info@chaltenet.com")
                    setTooltip(" ¡Copiado! ")
                    return false
                  }}
                >
                  <div className="d-flex flex-row w-100 flex-grow pr-2">
                    <FiMail color="#047" size={32} />
                    <p className="pl-2">info@chaltenet.com</p>
                  </div>
                </a>
              </OverlayTrigger>
              <Link to="https://wa.me/5492962720720" className="d-block w-100">
                <div className="d-flex w-100 flex-row flex-grow">
                  <FaWhatsapp color="#047" size={32} />
                  <p className="pl-2">+54 9 2966 123456</p>
                </div>
              </Link>
              <Link to="https://www.chaltenet.com" className="d-block w-100">
                <div className="d-flex w-100 flex-row flex-grow">
                  <FiGlobe color="#047" size={32} />
                  <p className="pl-2">www.chaltenet.com</p>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
